import '../HomePage/HomePage.css';
  
function Footer(props) {

    return (
        <div className="footer">
            <p>Made with ❤️ by the Cubstart team.</p>
        </div>
    );
}

export default Footer;
